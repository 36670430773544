const ENV = 'qa'

const BASE_URL = ENV === 'prod' ? 'https://gateway.paygol.com' : 'https://gatewayint.paygol.com'

const API_URL = ENV === 'prod' ? 'https://www.paygol.com' : 'https://gatewayint.paygol.com'

const PAYMENT_WINDOW_URL = ENV === 'prod' ? 'https://paymentwindow.paygol.com' : 'https://paymentwindowint.paygol.com'

export {
    ENV,
    BASE_URL,
    API_URL,
    PAYMENT_WINDOW_URL
}
